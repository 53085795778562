// --------------------------------------------------------------------------
// config.js
// --------------------------------------------------------------------------
// Used for anything that needs to be declared before your modules.
// --------------------------------------------------------------------------
import MarkerClusterer from './marker-clusterer'

var UNMISSABLE = window.UNMISSABLE || {};

UNMISSABLE.config = {

    messages: {
        zeroMatches: 'No experiences match the current filters.\nPlease change you filter selections.',
        multipleStarts: 'experiences are at this location'
    },

    unitLabels: {
        metric: {
            distance: 'KM',
            elevation: 'Metres'
        },
        imperial: {
            distance: 'Miles',
            elevation: 'Feet'
        }
    },

    markerImages: {},


    clusterGrid: 50,

    mapStyle: [{
            "featureType": "all",
            "elementType": "all",
            "stylers": [{
                "hue": "#00ffbc"
            }]
        },
        {
            "featureType": "administrative.country",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#da673c"
            }]
        },
        {
            "featureType": "administrative.locality",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#493E48"
            }]
        },
        {
            "featureType": "administrative.locality",
            "elementType": "labels.text.stroke",
            "stylers": [{
                    "color": "#ffffff"
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "geometry.fill",
            "stylers": [{
                    "color": "#87bbb4"
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "landscape.man_made",
            "elementType": "labels.text.fill",
            "stylers": [{
                    "color": "#6d3a3a"
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "landscape.natural",
            "elementType": "labels.text.fill",
            "stylers": [{
                    "color": "#ff0000"
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "landscape.natural.terrain",
            "elementType": "geometry.fill",
            "stylers": [{
                "color": "#619082"
            }]
        },
        {
            "featureType": "landscape.natural.terrain",
            "elementType": "labels.text.fill",
            "stylers": [{
                    "color": "#ff0000"
                },
                {
                    "visibility": "off"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "all",
            "stylers": [{
                    "visibility": "on"
                },
                {
                    "color": "#71a7a1"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "geometry.fill",
            "stylers": [{
                "color": "#7cc6be"
            }]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text",
            "stylers": [{
                    "visibility": "on"
                },
                {
                    "color": "#da673c"
                }
            ]
        },
        {
            "featureType": "poi",
            "elementType": "labels.text.fill",
            "stylers": [{
                    "visibility": "off"
                },
                {
                    "weight": "0.01"
                },
                {
                    "hue": "#ff0000"
                }
            ]
        },
        {
            "featureType": "poi.attraction",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#493E48"
            }]
        },
        {
            "featureType": "poi.business",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#493E48"
            }]
        },
        {
            "featureType": "poi.government",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#493E48"
            }]
        },
        {
            "featureType": "road",
            "elementType": "all",
            "stylers": [{
                "saturation": -70
            }]
        },
        {
            "featureType": "road",
            "elementType": "labels.text",
            "stylers": [{
                "color": "#493E48"
            }]
        },
        {
            "featureType": "road.highway",
            "elementType": "labels.text.fill",
            "stylers": [{
                "color": "#493E48"
            }]
        },
        {
            "featureType": "transit",
            "elementType": "all",
            "stylers": [{
                "visibility": "off"
            }]
        },
        {
            "featureType": "transit",
            "elementType": "labels",
            "stylers": [{
                "color": "#be6565"
            }]
        },
        {
            "featureType": "water",
            "elementType": "all",
            "stylers": [{
                    "visibility": "simplified"
                },
                {
                    "saturation": -60
                },
                {
                    "color": "#abd3de"
                }
            ]
        }
    ]

    // config dependant on google maps api

};
if (typeof google === 'object' && typeof google.maps === 'object') {

    UNMISSABLE.config.markerImages = {
        fallback: {
            url: '/map_pin.svg',
            size: new google.maps.Size(36, 50),
            scaledSize: new google.maps.Size(36, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(18, 50)
        },
        1: {
            url: '/map_pin.svg',
            size: new google.maps.Size(36, 50),
            scaledSize: new google.maps.Size(36, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(18, 50)
        },
        experienceClustered: {
            url: '/map_pin_solid.svg',
            size: new google.maps.Size(36, 50),
            scaledSize: new google.maps.Size(36, 50),
            origin: new google.maps.Point(0, 0),
            anchor: new google.maps.Point(18, 50)
        },
    };
}
/**
 * Created by simon on 20/11/2017.
 * initialise on domready by calling detailMap.init();
 */

UNMISSABLE.detailMap = function($, UNMISSABLE) {

    // the returned object

    var exports = {};

    // private local vars

    var _init = false;

    var m = {

        _map: null,

        _mapDiv: null,

        _experienceStream: [],

        _experiencePolyline: null,

        _experienceMarkerStart: null,

        _markers: [],

        _markerListeners: [],

        _elevationChart: null,

        _elevationData: null,

        _bounds: {},

        _mapStyles: UNMISSABLE.config.mapStyle,

        _fitMapToMarkers: function(visibleOnly) {

            m._bounds = new google.maps.LatLngBounds();

            var visibleCount = 0;

            $.each(m._markers, function(i, marker) {

                if (!visibleOnly || visibleOnly && marker.visible === true) {
                    m._bounds.extend(marker.position);
                    visibleCount++;
                }
            });

            if (visibleOnly && visibleCount === 0) {

                // no visible markers - set bounds to invisible markers so map remains in correct area
                alert(UNMISSABLE.config.messages.zeroMatchMessage);

                m._fitMapToMarkers(false);
            } else {

                m._map.fitBounds(m._bounds);
                m._map.panToBounds(m._bounds);
            }
        },

        _addRouteMarker: function() {

            const startLatLng = new google.maps.LatLng(m._experienceStream[0].data[0][0], m._experienceStream[0].data[0][1]);

            // read experience difficulty from global experienceData obj

            const experienceColour = experienceData.filterGrade;

            // default to green marker
            let experienceMarker = UNMISSABLE.config.markerImages.green;

            if (UNMISSABLE.config.markerImages[experienceColour]) {

                experienceMarker = UNMISSABLE.config.markerImages[experienceColour];
            }

            m._experienceMarkerStart = new google.maps.Marker({
                map: m._map,
                position: startLatLng,
                optimized: false,
                icon: experienceMarker
            });

            m._bounds.extend(startLatLng);

            m._map.fitBounds(m._bounds);
            m._map.panToBounds(m._bounds);
        },

        _templateInfoWindow: function(data) {
            return "Distance Covered: " + data.distanceCovered + " " + UNMISSABLE.config.unitLabels[UNMISSABLE.settings.distanceUnit].distance + "\n                      <br>Elevation: " + data.currentElevation + " " + UNMISSABLE.config.unitLabels[UNMISSABLE.settings.elevationUnit].elevation + "\n                      <br>Gradient: " + data.gradient + "%\n                      ";
        },

        _setClusters: function() {

            // cluster on markers for businesses

            m._experienceCluster = new MarkerClusterer(m._map, m._markers, {
                gridSize: UNMISSABLE.config.clusterGrid,
                averageCenter: true,
                maxZoom: 0,
                styles: [{
                    url: UNMISSABLE.config.markerImages.experienceClustered.url,
                    height: 50,
                    width: 36,
                    anchorText: [-7, 0],
                    textColor: '#ffffff',
                    textSize: 10,
                    anchorIcon: [17, 50]
                }],
                ignoreHidden: true
            });
        },

        _closestPoint: function(latlng, latlngArray) {

            // returns the index of the closest point in latlngArray to latlng
            // used to obtain elevation/gradient when hover on polyline

            var distArr = [];
            var dist = google.maps.geometry.spherical.computeDistanceBetween;

            for (var index in latlngArray) {

                distArr.push([index, dist(latlng, latlngArray[index])]);
            }

            return distArr.sort(function(a, b) {
                return a[1] - b[1];
            })[0][0];
        },

        _getGradient: function(i) {

            var distance = (m._experienceStream[1].data[i] - m._experienceStream[1].data[i - 1]) / 1000;
            var elevationChange = m._experienceStream[2].data[i] - m._experienceStream[2].data[i - 1];

            var angle = Math.asin(elevationChange / (1000 * distance));
            var grade = Math.tan(angle) * 100;

            return Math.round(1000 * grade / 1000);
        },

        _addMarkers: function() {

                // test the global var that holds facilityMarkers

                if (!Array.isArray(facilityMarkers) || facilityMarkers.length < 1) {
                    return false;
                }

                var infoWindow = new google.maps.InfoWindow({
                    pixelOffset: new google.maps.Size(-5, -40)
                });

                $.each(facilityMarkers, function(i, item) {

                    // define marker and add to map

                    var markerLatLng = new google.maps.LatLng(item.lat, item.lng);

                    m._markers[i] = new google.maps.Marker({
                        position: markerLatLng,
                        icon: UNMISSABLE.config.markerImages[fallback],
                        title: item.title,
                        index: i,
                        filter: {
                            type: item.type
                        }
                    });

                    m._bounds.extend(markerLatLng);

                    m._markers[i].setMap(m._map);

                    m._markerListeners[i] = m._markers[i].addListener('click', function(e) {

                        // open a info window with data

                        infoWindow.setPosition(e.latLng);

                        infoWindow.setContent(m._markers[i].title);

                        infoWindow.open(m._map);
                    });
                });

                // set map bounds now all markers added
                m._map.fitBounds(m._bounds);
                m._map.panToBounds(m._bounds);
            } // end m


    };

    exports.init = function() {

        // only init if the map_detail div exists

        if (_init || !document.getElementById('map_detail')) {
            return;
        }

        _init = true;

        // check we have the serverside rendered experienceData available in the template

        if (experienceData !== null && typeof experienceData !== 'object') {
            return;
        }

        // set up the base map
        var markerLatLng = new google.maps.LatLng(experienceData.departure_latitude, experienceData.departure_longitude);

        m._map = new google.maps.Map(document.getElementById('map_detail'), {
            zoom: 10,
            maxZoom: 20,
            minZoom: 2,
            styles: UNMISSABLE.config.mapStyle,
            streetViewControl: false,
            mapTypeControl: false
        });

        m._map.setCenter(markerLatLng);

        // load up the charts library and callback to drawChart
        // google.charts.load('current', { 'packages': ['corechart'] });
        // google.charts.setOnLoadCallback(m._drawChart);

        m._mapDiv = $(m._map.getDiv());

        // assign vars declared inline in the template from server side data

        // m._experienceStream = typeof experienceData.streamData === 'string' ? JSON.parse(experienceData.streamData) : experienceData.streamData;

        m._bounds = new google.maps.LatLngBounds();

        // m._addMarkers();

        m._markers[0] = new google.maps.Marker({
            position: markerLatLng,
            icon: UNMISSABLE.config.markerImages[1],
            title: experienceData.experience_name
        });

        m._bounds.extend(markerLatLng);

        m._markers[0].setMap(m._map);

        if (experienceData.radius) {
            let circle = new google.maps.Circle({
                map: m._map,
                radius: experienceData.radius * 1609,
                strokeOpacity: 0,
                fillColor: '#7cc6be'
            });
            circle.bindTo('center', m._markers[0], 'position');
            m._map.fitBounds(circle.getBounds())
        } else {
            m._map.panToBounds(m._bounds);
        }


        // m._map.fitBounds(m._bounds);

        $('#map_detail').fadeIn('slow');

        $('.toggle-key').on('click', function(e) {

            e.preventDefault();

            $(this).next().toggleClass('active');
        });
    };

    return exports;
}(jQuery, UNMISSABLE);

var _typeof = typeof Symbol === "function" && typeof Symbol.iterator === "symbol" ? function(obj) {
    return typeof obj;
} : function(obj) {
    return obj && typeof Symbol === "function" && obj.constructor === Symbol && obj !== Symbol.prototype ? "symbol" : typeof obj;
};

/**
 * initialise on domready by calling experienceMap.init();
 */

UNMISSABLE.mainMap = function($, UNMISSABLE) {

        // the returned object

        var exports = {};

        // private local vars

        var _init = false;

        var m = {

                _map: null,

                _mapDiv: null,

                _poiData: [],

                _imgBase: '',

                _infoWindows: [],

                _windowActive: false,

                _markers: [],

                _markerListeners: {},

                _bounds: {},

                _mapStyles: UNMISSABLE.config.mapStyle,

                _projection: null,

                _panPath: [],

                _panQueue: [],

                _panSteps: 50,

                _viewingMultiple: false,

                _isMobile: function _isMobile() {
                    var check = false;
                    (function(a) {
                        if (/(android|ipad|playbook|silk|bb\d+|meego).+mobile|avantgo|bada\/|blackberry|blazer|compal|elaine|fennec|hiptop|iemobile|ip(hone|od)|iris|kindle|lge |maemo|midp|mmp|netfront|opera m(ob|in)i|palm( os)?|phone|p(ixi|re)\/|plucker|pocket|psp|series(4|6)0|symbian|treo|up\.(browser|link)|vodafone|wap|windows (ce|phone)|xda|xiino/i.test(a) || /1207|6310|6590|3gso|4thp|50[1-6]i|770s|802s|a wa|abac|ac(er|oo|s\-)|ai(ko|rn)|al(av|ca|co)|amoi|an(ex|ny|yw)|aptu|ar(ch|go)|as(te|us)|attw|au(di|\-m|r |s )|avan|be(ck|ll|nq)|bi(lb|rd)|bl(ac|az)|br(e|v)w|bumb|bw\-(n|u)|c55\/|capi|ccwa|cdm\-|cell|chtm|cldc|cmd\-|co(mp|nd)|craw|da(it|ll|ng)|dbte|dc\-s|devi|dica|dmob|do(c|p)o|ds(12|\-d)|el(49|ai)|em(l2|ul)|er(ic|k0)|esl8|ez([4-7]0|os|wa|ze)|fetc|fly(\-|_)|g1 u|g560|gene|gf\-5|g\-mo|go(\.w|od)|gr(ad|un)|haie|hcit|hd\-(m|p|t)|hei\-|hi(pt|ta)|hp( i|ip)|hs\-c|ht(c(\-| |_|a|g|p|s|t)|tp)|hu(aw|tc)|i\-(20|go|ma)|i230|iac( |\-|\/)|ibro|idea|ig01|ikom|im1k|inno|ipaq|iris|ja(t|v)a|jbro|jemu|jigs|kddi|keji|kgt( |\/)|klon|kpt |kwc\-|kyo(c|k)|le(no|xi)|lg( g|\/(k|l|u)|50|54|\-[a-w])|libw|lynx|m1\-w|m3ga|m50\/|ma(te|ui|xo)|mc(01|21|ca)|m\-cr|me(rc|ri)|mi(o8|oa|ts)|mmef|mo(01|02|bi|de|do|t(\-| |o|v)|zz)|mt(50|p1|v )|mwbp|mywa|n10[0-2]|n20[2-3]|n30(0|2)|n50(0|2|5)|n7(0(0|1)|10)|ne((c|m)\-|on|tf|wf|wg|wt)|nok(6|i)|nzph|o2im|op(ti|wv)|oran|owg1|p800|pan(a|d|t)|pdxg|pg(13|\-([1-8]|c))|phil|pire|pl(ay|uc)|pn\-2|po(ck|rt|se)|prox|psio|pt\-g|qa\-a|qc(07|12|21|32|60|\-[2-7]|i\-)|qtek|r380|r600|raks|rim9|ro(ve|zo)|s55\/|sa(ge|ma|mm|ms|ny|va)|sc(01|h\-|oo|p\-)|sdk\/|se(c(\-|0|1)|47|mc|nd|ri)|sgh\-|shar|sie(\-|m)|sk\-0|sl(45|id)|sm(al|ar|b3|it|t5)|so(ft|ny)|sp(01|h\-|v\-|v )|sy(01|mb)|t2(18|50)|t6(00|10|18)|ta(gt|lk)|tcl\-|tdg\-|tel(i|m)|tim\-|t\-mo|to(pl|sh)|ts(70|m\-|m3|m5)|tx\-9|up(\.b|g1|si)|utst|v400|v750|veri|vi(rg|te)|vk(40|5[0-3]|\-v)|vm40|voda|vulc|vx(52|53|60|61|70|80|81|83|85|98)|w3c(\-| )|webc|whit|wi(g |nc|nw)|wmlb|wonu|x700|yas\-|your|zeto|zte\-/i.test(a.substr(0, 4))) {
                            check = true;
                        }
                    })(navigator.userAgent || navigator.vendor || window.opera);
                    return check;
                },

                _offsetMap: function _offsetMap() {

                    var leftMargin = 30,
                        rightMargin = 475,
                        mapOffset;

                    if ($('body').innerWidth() < 1024) {
                        rightMargin = 30;
                    }

                    // create a point of the top right corner of current MAP bounds
                    var pointMapTopRight = m._fromLatLngToPoint(m._map.getBounds().getNorthEast());

                    // Get pixel positions for edges of the POLYLINE bounds - polyline bounds are set in _showPolyline before _offsetMap is called

                    var pointPolylineBottomLeft = m._fromLatLngToPoint(m._bounds.getSouthWest());
                    var pointPolylineTopRight = m._fromLatLngToPoint(m._bounds.getNorthEast());

                    var availableLeft = pointPolylineBottomLeft.x - leftMargin;
                    var overflowRight = pointPolylineTopRight.x - (pointMapTopRight.x - rightMargin);

                    // Calculate left and right offsets
                    var leftOffset = leftMargin - pointPolylineBottomLeft.x;
                    var rightOffset = pointMapTopRight.x - rightMargin - pointPolylineTopRight.x;

                    // a negative availableLeft value indicates need to pan right

                    if (availableLeft < 0) {

                        // the same condition should be applied when shifting to right

                        // convert availableLeft to a positive and compare to space available on right

                        // overflow right will be negative if space is available

                        if (overflowRight < 0 && Math.abs(overflowRight) >= Math.abs(availableLeft)) {

                            mapOffset = Math.round((rightOffset - leftOffset) / 2);

                            // Pan the map by the offset calculated on the x axis
                            m._map.panBy(-mapOffset, 0);

                            // recalculate left point position after pan
                            pointPolylineBottomLeft = m._fromLatLngToPoint(m._bounds.getSouthWest());

                            if (pointPolylineBottomLeft.x <= leftMargin) {

                                // Leftmost point is still obscured - offset map again

                                m._offsetMap();
                            }
                        } else {

                            // Cannot offset map at this zoom level otherwise both leftmost and rightmost points will not fit
                            // Zoom out and offset map again

                            m._map.setZoom(m._map.getZoom() - 1);
                            m._offsetMap();
                        }
                    } else if (overflowRight > 0) {

                        // a positive overflowRight indicates that right edge of polyline is out side the bounds and needs to shift left

                        if (availableLeft >= overflowRight) {

                            // there is room to pan left

                            mapOffset = Math.round((availableLeft - overflowRight) / 2) + overflowRight;

                            // Pan the map by the offset calculated on the x axis
                            m._map.panBy(mapOffset, 0);

                            // recalculate right point position after pan
                            pointPolylineTopRight = m._fromLatLngToPoint(m._bounds.getNorthEast());

                            overflowRight = pointPolylineTopRight.x - (pointMapTopRight.x - rightMargin);

                            if (overflowRight > 0) {

                                // right edge point is still exceeding  - offset map again

                                m._offsetMap();
                            }
                        } else {

                            // we need to zoom out - it wont fit at this zoom level

                            // Cannot offset map at this zoom level otherwise both leftmost and rightmost points will not fit
                            // Zoom out and offset map again

                            m._map.setZoom(m._map.getZoom() - 1);
                            m._offsetMap();
                        }
                    }
                },

                _fromLatLngToPoint: function _fromLatLngToPoint(latLngLocation) {

                    // convert LatLng object to actual pixels - get the map projection and scale to be able to calculate it

                    // base scale on current map zoom level
                    var scale = Math.pow(2, m._map.getZoom());

                    // get latlng of visible map top left
                    var latLngTopLeft = new google.maps.LatLng(m._map.getBounds().getNorthEast().lat(), m._map.getBounds().getSouthWest().lng());

                    // convert top left latlng to a point
                    var pointTopLeft = m._map.getProjection().fromLatLngToPoint(latLngTopLeft);

                    // convert the latLngLocation arg to a point
                    var pointLocation = m._map.getProjection().fromLatLngToPoint(latLngLocation);

                    // calculate and return a new point

                    return new google.maps.Point(Math.floor((pointLocation.x - pointTopLeft.x) * scale), Math.floor((pointLocation.y - pointTopLeft.y) * scale));
                },

                _fitMapToMarkers: function _fitMapToMarkers(visibleOnly) {

                    m._bounds = new google.maps.LatLngBounds();

                    var visibleCount = 0;

                    $.each(m._markers, function(i, marker) {

                        if (!visibleOnly || visibleOnly && marker.visible === true) {
                            m._bounds.extend(marker.position);
                            visibleCount++;
                        }
                    });

                    if (visibleOnly && visibleCount === 0) {

                        // no visible markers - set bounds to invisible markers so map remains in correct area
                        alert(UNMISSABLE.config.messages.zeroMatches);

                        m._fitMapToMarkers(false);
                    } else {

                        m._map.fitBounds(m._bounds);
                    }

                    // refresh the clusters
                    m._experienceCluster.repaint();
                },

                _hideOtherMarkers: function _hideOtherMarkers(visibleIndex) {

                    $.each(m._markers, function(i, marker) {

                        var visibility = i == visibleIndex ? true : false;

                        if (marker.map === null) {
                            marker.setMap(m._map);
                        }

                        marker.setVisible(visibility);
                    });
                },

                _showAllMarkers: function _showAllMarkers() {

                    $.each(m._markers, function(i, marker) {
                        marker.setVisible(true);
                    });
                },

                _closeAllInfoWindows: function _closeAllInfoWindows() {

                    $('.b-experience-info-window-fixed').remove();

                },

                _addMarkers: function _addMarkers() {

                    $.each(m._poiData, function(i, poi) {

                        var markerLatLng = new google.maps.LatLng(poi.departure_latitude, poi.departure_longitude);

                        m._markers[i] = new google.maps.Marker({
                            position: markerLatLng,
                            icon: UNMISSABLE.config.markerImages[1],
                            title: poi.experience_name,
                            index: i,
                            filter: {
                                markerType: 'poi',
                                type: 'theme_' + poi.theme_id.toString()
                            }
                        });

                        m._bounds.extend(markerLatLng);

                        m._markers[i].setMap(m._map);

                        m._markerListeners[i] = m._markers[i].addListener('click', function() {

                            // if marker click occurs whilst viewing combine experiences in a single window do nothing
                            if (m._viewingMultiple) {
                                return true;
                            }

                            m._closeAllInfoWindows();

                            m._hideOtherMarkers(i);

                            // generate and show the experience info panel

                            var poiHtml = m._templatePlaceWindow([poi]);

                            m._mapDiv.append(poiHtml).fadeIn();
                        });


                        // info window open event - hide any active polylines and windows before open and show


                    });

                    // set map bounds now all markers added
                    m._map.fitBounds(m._bounds);
                },


        _templatePlaceItem: ({poi}) => {
            return `
              <div class="experience" data-index="${ poi.index}">
                    <div class="experience-detail">
                        <img src="${poi.image_url}" alt="${poi.experience_name}" style="max-width: 100%">
                        <h3 class="mt-2">${poi.experience_name}</h3>
                        <a class="cta btn btn-primary mt-3" href="/experiences/${poi.id}">view</a>
                    </div>
                </div>
            `;
        },

        _templatePlaceWindow: (pois) => {

            const html = `
             <div class="b-experience-info-window-fixed">
                <div class="b-experience-info-window-content-container">
                    <div class="b-experience-info-window-content">
                      ${ pois.length == 1 ? `<span class="row single-wrapper"><a class="b-experience-info-window-close col-2"><span>Close</span></a></span>` : ``}

                      ${ pois.length > 1 ? `<span class="row justify-content-around"><a class="b-experience-info-window-close col-2"><span>Close</span></a><h3 class="multiple-starts col-7 m-0 pt-2">${pois.length} experiences</h3><a class="experience-next col-3 btn">Next</a></span>` : ``}

                        <div class="experience-wrapper">

                            ${ pois.map(poi => m._templatePlaceItem({poi})).join('')}

                        </div>

                    </div>
                </div>
            </div>
            `;

            return html;

        },

        // smooth panning over distance greater than normally possible - not in use yet but might be handy

        _panTo: function _panTo(newLat, newLng) {

            if (m._panPath.length > 0) {

                m._panQueue.push([newLat, newLng]);
            } else {

                m._panPath.push('LOCKED'); // clear this before calling setTimeout

                var curLat = map.getCenter().lat();
                var curLng = map.getCenter().lng();
                var dLat = (newLat - curLat) / m._panSteps;
                var dLng = (newLng - curLng) / m._panSteps;

                for (var i = 0; i < m._panSteps; i++) {
                    m._panPath.push([curLat + dLat * i, curLng + dLng * i]);
                }

                m._panPath.push([newLat, newLng]);
                m._panPath.shift(); // remove LOCKED

                setTimeout(m._doPan, 20);
            }
        },

        _doPan: function _doPan() {

            var next = m._panPath.shift();

            if (next != null) {

                m._map.panTo(new google.maps.LatLng(next[0], next[1]));

                setTimeout(m._doPan, 20);
            } else {

                var queued = m._panQueue.shift();

                if (queued != null) {
                    m._panTo(queued[0], queued[1]);
                }
            }
        },

        _setClusters: function _setClusters() {

            /**
             *
             * @type {MarkerClusterer}
             * @private
             *
             * IMPORTANT: averageCenter - when TRUE this centralises the clustered marker within the group.
             * we use this to determine whether experiences have the same exact start point and should be shown as combined window.
             * If it was false the clustered marker would be positioned at the location of the first marker in the group
             *
             */

            m._experienceCluster = new MarkerClusterer(m._map, m._markers, {
                gridSize: UNMISSABLE.config.clusterGrid,
                averageCenter: true,
                maxZoom: 0,
                styles: [{
                    url: UNMISSABLE.config.markerImages.experienceClustered.url,
                    height: 50,
                    width: 36,
                    anchorText: [-7, 0],
                    textColor: '#ffffff',
                    textSize: 10,
                    anchorIcon: [17, 50]
                }],
                ignoreHidden: true
            });

            google.maps.event.addListener(m._experienceCluster, "click", function (cluster) {

                m._closeAllInfoWindows();

                // test if clustered markers have same exact location - if they do we need a combined info window

                var clusteredMarkers = cluster.getMarkers();
                var clusteredPOIs = [];

                var clusterCenter = cluster.getCenter();
                var firstMarkerPosition = clusteredMarkers[0].getPosition();

                // when averageCenter is true if centre of cluster is same as the first marker then we need to stack

                // standardize to 5 dec places to avoid miniscule variations

                var clusterCenterLat = parseFloat(clusterCenter.lat()).toFixed(5);
                var clusterCenterLng = parseFloat(clusterCenter.lng()).toFixed(5);

                var posLat = parseFloat(firstMarkerPosition.lat()).toFixed(5);
                var posLng = parseFloat(firstMarkerPosition.lng()).toFixed(5);

                var differenceLat = Math.abs(clusterCenterLat - posLat);
                var differenceLng = Math.abs(clusterCenterLng - posLng);

                if (0.001 > differenceLat && 0.001 > differenceLng) {

                    // show multiple experiences in single window - trigger marker click

                    m._hideOtherMarkers();

                    for (var i in clusteredMarkers) {

                        var poiData = m._poiData[clusteredMarkers[i].index];

                        poiData['index'] = clusteredMarkers[i].index;

                        clusteredPOIs.push(poiData);

                        // remove the events from the individual markers in the cluster - now tested in marker click event instead

                        //google.maps.event.removeListener(m._markerListeners[clusteredMarkers[i].index]);

                        m._markers[clusteredMarkers[i].index].addListener('click', function () {

                            // hide all other markers and clusters when viewing open window?

                        });
                    }

                    // create new combined info window for markers in the cluster

                    var poiHtml = m._templatePlaceWindow(clusteredPOIs);

                    m._mapDiv.append(poiHtml).fadeIn();

                    $('.experience-next').show();

                    m._windowActive = true;

                    // flag multiple view status - used to prevent click event on individual markers

                    m._viewingMultiple = true;

                    // show polyline for first rotue in cluster

                    setTimeout(function () {

                        // showing polyline may trigger cluster to reset as zoom level changes - delay execution slightly to prevent errors

                        // m._showPolyline(m._polylines[clusteredMarkers[0].index]);

                        m._markers[clusteredMarkers[0].index].setVisible(true);
                    }, 300);

                }
            });
        },

        _bindMapEvents: function _bindMapEvents() {

            // bind event for navigating through experiences in the info window

            m._mapDiv.on('click', '.experience-next', function (e) {

                var infoWindow = $(e.target).parent().parent();

                var experience = $('.experience-wrapper > div:first', infoWindow),
                    nextRoute = experience.next(),
                    elevationDisplay = $('.experience-elevation', infoWindow),
                    distanceDisplay = $('.experience-distance', infoWindow),
                    slideContainer = $('.experience-wrapper', infoWindow);

                var count = slideContainer.children.length;

                elevationDisplay.addClass('off');
                distanceDisplay.addClass('off');

                nextRoute.fadeIn(500, function () {

                    elevationDisplay.removeClass('off');
                    distanceDisplay.removeClass('off');
                });

                experience.fadeOut(500, function () {

                    experience.appendTo(slideContainer);
                });

                // show the relevant experience polyline

                var index = parseInt(nextRoute.attr('data-index'));

                m._hideOtherMarkers(index);
            });

            // bind close event

            m._mapDiv.on('click', '.b-experience-info-window-close', function (e) {

                $('.b-experience-info-window-fixed').remove();

                m._viewingMultiple = false;

                m._windowActive = false;


                m._showAllMarkers();

                m._fitMapToMarkers(true);

                // m._filterMarkers(e);
            });

        },

    };
    // end m


    exports.init = function () {

        if (_init || !document.getElementById('map')) {
            return;
        }

        _init = true;

        m._map = new google.maps.Map(document.getElementById('map'), {
            zoom: 10,
            maxZoom: 17,
            minZoom: 3,
            styles: UNMISSABLE.config.mapStyle,
            streetViewControl: false,
            mapTypeControl: false,
            zoomControl: true,
            zoomControlOptions: {
                position: google.maps.ControlPosition.RIGHT_TOP
            }
        });

        m._mapDiv = $(m._map.getDiv());

        m._poiData = [...pois]; // array declared in template script tag

//        m._accomData = accommodationMarkers; // array declared in template script tag

        m._bounds = new google.maps.LatLngBounds();

        m._addMarkers();

        m._setClusters();

        m._bindMapEvents();

        $('#map').fadeIn('slow');
    };

    return exports;
}(jQuery, UNMISSABLE);
'use strict';

/**
 * initialise on domready by calling settings.init();
 * settings can be access globally via UNMISSABLE.settings.settingName
 *
 * use inconjunction with config unitLabels to access the text display labels
 * UNMISSABLE.config.unitLabels[UNMISSABLE.settings.elevationUnit].elevation
 */

UNMISSABLE.settings = function ($, UNMISSABLE) {

    // the returned object

    var exports = {};

    // private local vars

    var _init = false;

    // elevation and distance values are metric by default

    exports.renderElevation = function (value) {

        if (UNMISSABLE.settings.elevationUnit === 'imperial') {

            // if settings imperial we need to convert to feet

            value *= 3.2808;
        }

        var html = '<span>' + parseInt(value) + '</span><span>' + UNMISSABLE.config.unitLabels[UNMISSABLE.settings.elevationUnit].elevation + '</span>';

        return html;
    };

    exports.renderDistance = function (value) {

        if (UNMISSABLE.settings.distanceUnit === 'imperial') {

            // if settings imperial we need to convert to miles

            value *= 0.62137;
        }

        var html = '<span>' + parseInt(value) + '</span><span>' + UNMISSABLE.config.unitLabels[UNMISSABLE.settings.distanceUnit].distance + '</span>';

        return html;
    };

    exports.init = function () {

        if (_init) {
            return;
        }

        _init = true;

        if (window.localStorage) {

            exports.distanceUnit = localStorage.getItem('setting-distance');
            exports.elevationUnit = localStorage.getItem('setting-elevation');

            if (!exports.distanceUnit) {
                exports.distanceUnit = 'metric';
            }

            if (!exports.elevationUnit) {
                exports.elevationUnit = 'metric';
            }

            // store unit prefs in local storage when radio changed

            $('.setting-option input').on('change', function (e) {
                localStorage.setItem(e.target.name, $('input[name=' + e.target.name + ']:checked').val());
            });
        } else {

            // if no local storage available default to metric

            exports.elevationUnit = 'metric';
            exports.distanceUnit = 'metric';
        }

        // set radio checked status to match initial value
        $('input[name=setting-distance][value=' + exports.distanceUnit + ']').prop('checked', true);
        $('input[name=setting-elevation][value=' + exports.elevationUnit + ']').prop('checked', true);
    };

    return exports;
}(jQuery, UNMISSABLE);

export default function initMain() {
  var modules = ['settings','mainMap', 'detailMap'];
  for (var i = 0; i < modules.length; i++) {
      UNMISSABLE[modules[i]].init();
  }
}
